var Reactions = {

    like: function (item_id , item_type) { 
       
        $.ajax({
            type: "post",
            url: "/api/reactions",
            data: {
                item_id : item_id,
                item_type : item_type,
                type : 'like'
            },
            success: function (data) {
                 $('[data-refresh-after-reactions-change][data-'+item_type+'_id="'+item_id+'"]').each(function () {
                    load_partial( $(this) );
                });
             },
            error: function (data) {
                if (data.status == 401) {
                    openPartialModal(null, { partial: 'account.partials.signup' }, null, function () { 
                        Reactions.like(item_id, item_type);
                    }) ;
                } else {
                    actionResponseModal( data.responseJSON.error, 'error', '.actions-block .save' );
                }
            }

        } );
    },
    unlike: function (item_id , item_type) { 
       
        $.ajax({
            type: "delete",
            url: "/api/reactions",
            data: {
                item_id : item_id,
                item_type : item_type 
            },
            success: function (data) {
                $('[data-refresh-after-reactions-change][data-'+item_type+'_id="'+item_id+'"]').each(function () {
                    load_partial( $(this) );
                });
            },
            error: function( data ) {
                actionResponseModal( data.responseJSON.error, 'error', '.actions-block .save' );
            }

        } );
    }
}