 var Pages = {
     gallery: function(target) {
         var $sliderGallery = new Swiper(target, {
             slidesPerView: 1,
             loop: !0,
             effect: 'fade',
             speed: 1000,
             fadeEffect: {
                 crossFade: true
             },
             navigation: {
                 nextEl: target + ' .swiper-button-next',
                 prevEl: target + ' .swiper-button-prev',
             },
         })
     },
     particles: function(target) {

         particlesJS(target, {
                 "particles": {
                     "number": {
                         "value": 80,
                         "density": {
                             "enable": true,
                             "value_area": 800
                         }
                     },
                     "color": {
                         "value": "#dea947"
                     },
                     "shape": {
                         "type": "circle",
                         "stroke": {
                             "width": 0,
                             "color": "#000000"
                         },
                         "polygon": {
                             "nb_sides": 5
                         },
                         "image": {
                             "src": "img/github.svg",
                             "width": 100,
                             "height": 100
                         }
                     },
                     "opacity": {
                         "value": 0.6155448755889102,
                         "random": true,
                         "anim": {
                             "enable": true,
                             "speed": 1,
                             "opacity_min": 0.1,
                             "sync": false
                         }
                     },
                     "size": {
                         "value": 3,
                         "random": true,
                         "anim": {
                             "enable": false,
                             "speed": 40,
                             "size_min": 0.1,
                             "sync": false
                         }
                     },
                     "line_linked": {
                         "enable": false,
                         "distance": 150,
                         "color": "#ffffff",
                         "opacity": 0.4,
                         "width": 1
                     },
                     "move": {
                         "enable": true,
                         "speed": 1,
                         "direction": "none",
                         "random": false,
                         "straight": false,
                         "out_mode": "out",
                         "bounce": false,
                         "attract": {
                             "enable": true,
                             "rotateX": 600,
                             "rotateY": 1200
                         }
                     }
                 },
                 "interactivity": {
                     "detect_on": "canvas",
                     "events": {
                         "onhover": {
                             "enable": false,
                             "mode": "repulse"
                         },
                         "onclick": {
                             "enable": false,
                             "mode": "push"
                         },
                         "resize": true
                     },
                     "modes": {
                         "grab": {
                             "distance": 400,
                             "line_linked": {
                                 "opacity": 1
                             }
                         },
                         "bubble": {
                             "distance": 400,
                             "size": 40,
                             "duration": 2,
                             "opacity": 8,
                             "speed": 3
                         },
                         "repulse": {
                             "distance": 200,
                             "duration": 0.4
                         },
                         "push": {
                             "particles_nb": 4
                         },
                         "remove": {
                             "particles_nb": 2
                         }
                     }
                 },
                 "retina_detect": true
             }

         );


     }
 }
