authForm = {
 
	openModal:function(target){
        $('.authFormContent').hide();
        $('.authFormContent.authFormContent-'+target).show();
        $('#authFormModal').modal('show');        
  
    },
    toggleModalView:function(target){
        $('.authFormContent').hide();
        $('.authFormContent.authFormContent-'+target).show();
    }
} 
 
function saveItem($this,$module,id){   
    $.ajax({                
        type : "POST",               
        url  : "/api/favorites",        
        data: { 
            item_id : id,               
            item_type : $module,
        },
        success:function(data){
            load_partial($this.closest('.partial'));  
            $this.closest('.save-item').toggleClass('saved');  
            actionResponseModal(data.message,'success');
        },
        error:function(data){           
           actionResponseModal(data.message,'error')
        },

    });
}



 
var UserAvatar = {
    filename : null,
    original_filename : null,
    init : function(target){

        var $this = this;

        $('#'+target + '-save').on('click',function(){
            if($this.filename != null){
                $.ajax({
                    
                    type : "POST",               
                    url  : "/api/users/avatar",
                    
                    data: { 
                        filename            : $this.filename,
                        original_filename : $this.original_filename
                    },

                    success:function(data){
                       window.location.reload();
                    }
 
                });
            }else{
                alert('select an image first');
            }
        })

        $('#'+target).closest('.modal').on('shown.bs.modal',function(){

            var uploader = new plupload.Uploader({
                runtimes        : 'html5,flash,silverlight,browserplus',
                browse_button   : target + '-pickfiles',
                container       : target + '-container',
                drop_element    : target + '-dropzone',
                url             : '/upload',
                headers:{
                    'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')
                },
                filters : {
                    max_file_size : '10mb',
                    mime_types: [
                        {title : "Images", extensions : "jpg,jpeg,png"}
                    ]
                }
            });

            $('#'+target + '-uploadfiles').click(function(e) {
                uploader.start();
                e.preventDefault();
            });

            uploader.init();

            uploader.bind('FilesAdded', function(up, files) {

                $.each(files, function(i, file) {
                    var size = plupload.formatSize(file.size);
                    
                    img_thumb = '/assets/frontend/default/img/upload_placeholder.png';
                    var parts = file.name.split('.');
                    if(parts[parts.length - 1] == 'pdf'){
                        img_thumb =  '/assets/frontend/default/img/pdf.png';    
                    }

                    var markup = `  <div id="${file.id}" style=" padding:10px;   border: 1px solid #dadada;">
                                        <div class="quoteFileItem" >
                                            <input type="hidden" name="filename"          value="">
                                            <input type="hidden" name="original_filename" value="${file.name}">
                                            <input type="hidden" name="metadata_size[]" value="${file.size}">
                                            <div class="row no-gutters align-items-center">                                        
                                                <div class="col quoteFileItem-preview">                                               
                                                    <img src="${img_thumb}" class="img-fluid">                                                 
                                                </div>
                                                <div class="col quoteFileItem-info text-left">       
                                                    <b>${file.name}</b>
                                                    <div class="size">${size}</div>      
                                                    <a class="btn btn-error btn mt-3" onClick="$('#${file.id}').remove(); $('.dropzone').show(); $('.actions').hide(); ">Remove</a>  
                                                    <div class="progress progress-small" style="display:none;height:6px;">
                                                        <div class="progress-bar progress-bar-danger" style="background-color: rgb(34, 137, 233);width: 0%;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`;

                    $('#'+target + '-uploaded-files').append(markup);
                });
                up.refresh();

                $('.dropzone').hide();
               


                uploader.start();
            });

            uploader.bind('UploadProgress', function(up, file) {
                $('#' + file.id + " .progress").fadeIn('fast');
                $('#' + file.id + " .progress .progress-bar").width(file.percent + "%");
            });

            uploader.bind('Error', function(up, err) {

                AjaxForms.form_submit_response("Message: " + err.message + (err.file ? ", File: " + err.file.name : "") ,'error');
                up.refresh();

            });

            uploader.bind('FileUploaded', function(up, file,ee) {
                var response = (JSON.parse(ee.response));

    
                

                var parts = file.name.split('.');
                if(parts[parts.length - 1] != 'pdf'){
                    var splittedPath = response.path.split('/')
                    var img_thumb = ''; 
                    for(var $i =0;$i < splittedPath.length; $i++ ){
                        if($i+1 == splittedPath.length){
                            img_thumb += '/50/60';
                        }
                        img_thumb += '/'+splittedPath[$i];                                             
                    }
                    $('#' + target + ' img').attr("src", img_thumb);
                }                            
                
                $('#' + file.id + " .progress").fadeOut('fast');
                $('#' + file.id + ' input[name="filename"]').val(response.path);
                
                $this.filename          = response.path;
                $this.original_filename = file.name;
                $('.actions').show();
               
            });
        })


    }
};

 
/* Function to animate height: auto */
function autoHeightAnimate(element, time){
    var     curHeight = element.height(), // Get Default Height
            autoHeight = element.css('height', 'auto').height(); // Get Auto Height
            element.height(curHeight); // Reset to Default Height
            element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
}

var accountNav = {
    init:function(target){        
        $slider = new Swiper( target, {
            slidesPerView: 'auto',
            centeredSlides: true,
            touchRatio: 0.2,
            threshold:5,
            centerInsufficientSlides: true,
            centeredSlidesBounds: true,
            breakpoints: {
                320: {
                    spaceBetween: 5,
                },
                768: {
                    spaceBetween: 10,
                },
                992: {
                    spaceBetween: 20,
                },
            },
            on: {
                init: function(target) {
                    console.log(accountNav.getActiveSlide());
                }
            }       
        } );

    },
    getActiveSlide: function(target){
        let index = 0;
        
        if($('.account-nav-item .active').closest('.account-nav-item').length>0){
            index = $('.account-nav-item .active').closest('.account-nav-item').index();
        }
        return index;
    }
}