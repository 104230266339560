var partials_pull  = [];    

function loading_partial(){
    if($.isEmptyObject(partials_pull)){
        $('body').removeClass('partials-loading');
    }else if(!$.isEmptyObject(partials_pull) ){
        $('body').addClass('partials-loading')
    }
}

    
function append_partial( container , cb){

    if(container.length == 0){
        return false;
    }

    var unique_partial_id = Math.floor(Math.random()* 1000000),
        d = container.data(),
        data = {};

    for(var i in d){
        if(i!='partial'){
            data[i] = d[i];
        }
    }

    $('body').addClass('loading');

    container.css('pointer-events','none');
    container.css('min-height',container.height());
    container.addClass('loading');

    if (container.attr('id') == "products-list-filter") {
        container.append(products_loading_template);
    }
    if (container.attr('data-partial') == "products.aggregates") {
        container.append(products_filter_loading_template);
    }

    if(data){
        data.current_url = window.location.pathname;
    }
    if(container.data('xhr')){
        container.data('xhr').abort();
    }

    var ext_domain = '';
    if (container.data('ext_domain')) {
        ext_domain = container.data('ext_domain');
    }

   $.ajax({
        url     : ext_domain + '/partial/' + d.partial,
        data    : data,
        method  : 'GET',

        success: function (data) {
            var newContent = $(data.markup).find('.widget-content');
            var t = container.find('.widget-content:first').append(newContent.html());

            if(newContent.text().length == 0){
                container.hide();
            }else{
                container.show();
            }
            
            container.css('pointer-events','auto');
            
            container.removeClass('loading');
            $('body').removeClass('loading');

            container.css('pointer-events','auto');
            container.css('min-height','auto');
            $('#' + newContent.attr('id')).removeClass('loading');

            container.css('min-height','0');

            if(typeof(cb) !== 'undefined'){
                cb(newContent);
            }
            loading_partial();

            initForm(container);
         
        },
        error: function (response) {
            loading_partial();
        }
    });

    loading_partial();
}
function load_partial( container , cb){

    if(container.length == 0){
        return false;
    }

    var unique_partial_id = Math.floor(Math.random()* 1000000),
        d = container.data(),
        data = {};

    for(var i in d){
        if(i!='partial'){
            data[i] = d[i];
        }
    }
    

    $('body').addClass('loading');
    container.css('opacity',0.5);
    container.css('pointer-events','none');
    container.css('min-height',container.height());
    container.addClass('loading');
    //container.append('<div class="parial-loader">Loading...</div>');

    if (container.attr('id') == "products-list-filter") {
        container.append(products_loading_template);
    }
    if (container.attr('data-partial') == "products.aggregates") {
        container.append(products_filter_loading_template);
    }



    if(data){
        data.current_url = window.location.pathname;
    }
    if(container.data('xhr')){
        container.data('xhr').abort();
    }

    var ext_domain = '';
    if (container.data('ext_domain')) {
        ext_domain = container.data('ext_domain');
    }

   $.ajax({
        url     : ext_domain + '/partial/' + d.partial,
        data    : data,
        method  : 'GET',

        success: function (data) {
            var newContent = $(data.markup);            
            var t = container.replaceWith(newContent);

            newContent.css('pointer-events','auto');

            if(newContent.text().length == 0){
                newContent.hide();
            }else{
                newContent.show();
            }

            newContent.removeClass('loading');
            $('body').removeClass('loading');
            // container.find('.parial-loader').remove();
            $('#' + newContent.attr('id')).removeClass('loading');

            newContent.css('min-height','0');
            newContent.css('opacity','1');

            if(typeof(cb) !== 'undefined'){
                cb(newContent);
            }

            loading_partial();
            initForm(newContent); 
        },
        error: function (response) {
            loading_partial();
        }
    });

    loading_partial();
    
}

function openPartialModal( $theTriggerer, data,cb){   
    $('.partial-modal').remove();
    var params ='';
    if($theTriggerer){
        $theTriggerer.css('pointer-events','none');    
    }    
    if(typeof data !== 'undefined' && data){        
        for (var key of Object.keys(data)) {
            if(data[key] !== ''){                    
                params += `data-${key}="${data[key]}"`;
            }
        }       
    }
    var partialModal = `<div class="partial partial-modal" id="partialModal" ${params} style="display:none"></div>`;
    $('body').append(partialModal); 
       
    container = $("#partialModal");
    if(container.length == 0){
        return false;
    }

    var unique_partial_id = Math.floor(Math.random()* 1000000),
        d = container.data(),
        data = {};

    for(var i in d){
        if(i!='partial'){
            data[i] = d[i];
        }
    }

    if(data){
        data.current_url = window.location.pathname;
    }
    var ext_domain = '';
    if (container.data('ext_domain')) {
        ext_domain = container.data('ext_domain');
    }
    $.ajax({
        url     : ext_domain + '/partial/' + d.partial,
        data    : data,
        method  : 'GET',
        success: function (data) {
            var newContent = $(data.markup);            
            var t = container.replaceWith(newContent);

            if(newContent.text().length == 0){
                newContent.remove();
            }else{
                newContent.modal('show').on('hidden.bs.modal', function () {
                    newContent.remove();   
                    if($theTriggerer){
                        $theTriggerer.css('pointer-events','auto');     
                    }            
                });  
            }
            if(typeof(cb) !== 'undefined'){
                cb(newContent);
                if($theTriggerer){
                    $theTriggerer.css('pointer-events','auto');
                }
            }
            loading_partial();
            initForm(newContent); 
        },
        error: function (response) {
            loading_partial();
            if($theTriggerer){
                $theTriggerer.css('pointer-events','auto');
            }
        }
    });
    loading_partial();


} 
