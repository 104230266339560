function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
var monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function dateSQlToHuman($value) {
    let arrayValue = $value.split("-");
    let date = new Date(Number(arrayValue[0]), Number(arrayValue[1]) - 1, Number(arrayValue[2]));
    let ye = new Intl.DateTimeFormat('en', {
        year: 'numeric'
    }).format(date);
    let mo = new Intl.DateTimeFormat('en', {
        month: 'short'
    }).format(date);
    let da = new Intl.DateTimeFormat('en', {
        day: '2-digit'
    }).format(date)
}

function dateHumanToSQl(date) {
    if (typeof date !== 'undefined') {
        let arrayValue = date.replace(',', '').split(" ");
        var year = arrayValue[2];
        var month = (Number(monthsShort.indexOf(arrayValue[0])) + 1);
        month = `0${month}`.slice(-2);
        var day = arrayValue[1];
        day = `0${day}`.slice(-2);
        var value = year + '-' + month + '-' + day;
        return value;
    }
}

var timeout = false,
    rtime, delta = 500;

$(function () {



    $('.burger').click(function () {
        $('body').toggleClass('show-menu');
        $('body').removeClass('show-search');

        return false;
    });
    $('.responsive-overlay').click(function () {
        $('body').removeClass('show-menu');
        $('body').removeClass('show-filters');
        return false;
    });
    $('.page-body').addClass('loaded');


    $(document).on('click', '[data-action="load-more"]', function () {
        var p = $(this).closest('.partial'),
            caller = $(this),
            page = p.data('page');

        if (page == null || page < 1) {
            page = 1;
        }
        p.data('page', page + 1);
        caller.addClass('loading');
        console.log(p);
        append_partial(p, function () {
            caller.removeClass('loading');
        });
        return false;
    });




    $('.partial').each(function () {
        var $thePartial = $(this);
        load_partial($thePartial);
    });

    if ($(window).scrollTop() > 50) {
        $('body').addClass('scrolled');
    }
    var lastScrollTop = 0;
    $(document).on('scroll', function () {
        _scrollTop = $(window).scrollTop();
        if (_scrollTop >= 50) {
            $('body').addClass('scrolled');
        } else {
            $('body').removeClass('scrolled');
        }
    });
    initForm('body');
    waypoints();
});

function scroll2position(pos, t) {
    $('html, body').animate({
        scrollTop: pos
    }, t || 500);
}

function scroll2element(el) {
    var position = $(el).offset();
    var $padding = $('body').css('paddingTop').replace('px', '');
    var y = position.top - $padding;

    $('html, body').stop().animate({
        scrollTop: y
    }, 'slow');

}



function dd(d) {
    console.log(d);
}

function partialPager($this, $page) {
    var $thePartial = $this.closest('.partial');
    $thePartial.attr('data-page', $page);
    load_partial($thePartial);
}

function partialSortTrigger($this) {
    var $thePartial = $this.closest('.partial');
    var $way = $this.data('sortway');
    var $term = $this.data('sortterm');
    $thePartial.attr('data-sortway', $way);
    $thePartial.attr('data-sortterm', $term);
    load_partial($thePartial);
}

function waypoints() {

    $('.way-point:not(.way-point-ready),.waypoint-animate:not(.way-point-ready)').each(function () {
        $(this).waypoint(function () {
            $(this.element).addClass('shown');
        }, {
            offset: '90%'
        });
        $(this).addClass('way-point-ready');
    });



}


function initForm(target) {
    'use strict';

    var t = typeof (target) !== 'undefined' ? target : 'body';

    if ($(t).find("textarea.editor").length > 0) {

        $(t).find("textarea.editor").each(function () {
            if (typeof CKEDITOR !== 'undefined') {
                CKEDITOR.replace($(this).attr('id'), {
                    allowedContent: true,
                    entities_greek: false,
                    removePlugins: 'easyimage, cloudservices',
                    on: {
                        instanceReady: function (evt) {
                            var editor = evt.editor,
                                body = editor.document.getBody();
                            body.setAttribute('class', 'skin-guide');
                        }
                    }
                });
            }
        });
    }



    $(t).find('.select2, select[data-plg="select2"]').each(function () {
        var data = $(this).data();
        var _this = $(this);
        var $dropParent =data.dropparent || null;
        var options = {
            dropdownParent: $dropParent
        };
        if (data.ajax) {
            options = $.extend(false, options, {
                placeholder: data.placeholder,
                allowClear: data.allowClear || false,
                ajax: {
                    url: API + '/' + data.ajax,
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        var search_for = typeof (params.term) !== 'undefined' ? params.term : '';
                        return {
                            q: data.search + '=@' + search_for,
                            page: params.page
                        };
                    },
                    processResults: function (res, params) {
                        params.page = params.page || 1;
                        var value_field = data.value || 'id';
                        var label_field = data.label || data.search;
                        return {
                            results: $.map(res.data, function (item) {
                                return {
                                    all_data: item,
                                    text: item[label_field],
                                    id: item[value_field]
                                }
                            }),
                            pagination: {
                                more: (params.page * 30) < data.total_count
                            }
                        };
                    },
                    cache: true
                },
                minimumInputLength: 0
            });
        }

        $(this).select2(options).on('select2:open', function () {
            $(this).closest('.form-group').addClass('filled');
            $('.select2-dropdown').closest('.select2-container').addClass('z-index-dropdown');
        }).on('select2:close', function () {
            if ($(this).val() == '0' || $(this).val() == '' || $(this).val() == 'undefined' || $(this).val() == null) {
                $(this).closest('.form-group').removeClass('filled');
            }
            $('.select2-dropdown').closest('.select2-container').removeClass('z-index-dropdown');
        });

    });

    $(t).find(".form-group").each(function () {
        var $formGroup = $(this);
        if (!$formGroup.hasClass('eventReady')) {
            $formGroup.click(function () {
                if ($formGroup.find('.select2,select[data-plg="select2"]').length > 0) {
                    $formGroup.find('select.select2-hidden-accessible ').select2('open')

                } else {
                    $formGroup.find('.form-control').focus();
                }
            });

            $formGroup.find(".form-control").focusin(function () {
                $(this).closest(".form-group").addClass('filled');
            });
            $formGroup.find(".form-control").focusout(function () {
                if ($(this).val() == '') {
                    $(this).closest(".form-group").removeClass('filled');
                }

            });
            $formGroup.addClass('eventReady');

        }

    });


    waypoints();
}

function inlineUploader(id) {
    var target = 'uploader-' + id;
    var uploader = new plupload.Uploader({
        runtimes: 'html5,flash,silverlight,browserplus',
        browse_button: target + '-pickfiles',
        container: target + '-container',
        drop_element: target + '-dropzone',
        url: "/upload",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        multipart_params: {
            'key': id
        },
        filters: {
            max_file_size: '10mb',
            mime_types: [{
                title: "Document",
                extensions: "pdf"
            }]
        }
    });

    uploader.bind('Init', function (up, params) {

    });

    $('#' + target + '-uploadfiles').click(function (e) {
        uploader.start();
        e.preventDefault();
    });

    uploader.init();

    uploader.bind('FilesAdded', function (up, files) {
        $.each(files, function (i, file) {
            var markup = `  <div id="${file.id}">                                
                                <div class="progress progress-small" style="display:none;">
                                    <div class="progress-bar progress-bar-danger" style="background-color: #8eb82f;width: 0%;"></div>
                                </div>
                                <small class="no-margin">${file.name} (${plupload.formatSize(file.size)})</small>
                            </div>`;
            $('#' + target + '-uploaded-files').html(markup);
        });
        up.refresh();
        uploader.start();
    });

    uploader.bind('UploadProgress', function (up, file) {
        $('#' + file.id + " .progress").fadeIn('fast');
        $('#' + file.id + " .progress .progress-bar").width(file.percent + "%");
    });

    uploader.bind('Error', function (up, err) {
        $('#' + target + '-filelist').append("<div>Error: " + err.code +
            ", Message: " + err.message +
            (err.file ? ", File: " + err.file.name : "") +
            "</div>"
        );
        up.refresh();
    });
    uploader.bind('FileUploaded', function (up, file, ee) {
        $('#' + target + ' input[name="file"]').val(id + '/' + file.name);
    });
};

function formatData(data) {
    if (!data.id) {
        return data.text;
    }
    var $result = $(
        '<span><img src="/assets/frontend/default/img/assets/' + data.text.toLowerCase() + '.png"/> ' + data.text + '</span>'
    );
    return $result;
};


function actionResponseModal(msg, type, container, cb) {
    var $timeout = 0;

    /*if($('body').hasClass('modal-open') ){
      $('.modal').modal('hide');
      $timeout = 500;  
    }*/

    var with_ajax_modal = false;
    if ($('#ajaxModal:visible').length == 1) {
        with_ajax_modal = true;
        $('#ajaxModal').animate({
            opacity: 0
        }, 300);
    }

    setTimeout(function () {
        var $d = `<div class="modal fade" id="responseModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-md" role="document">
                            <div class="modal-content">
                                <a href="#" class="close-modal" tabindex="0" onClick="$('#responseModal').modal('hide');return false;">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 13 13" style="enable-background:new 0 0 13 13;" xml:space="preserve">
                                        <path class="st0" d="M12.9,11.7l-1.2,1.2L6.9,8.1l-4.8,4.8l-1.2-1.2l4.8-4.8L0.9,2.1l1.2-1.2l4.8,4.8l4.8-4.8l1.2,1.2L8.1,6.9 L12.9,11.7z"></path>
                                    </svg>
                                </a>
                                <div class="modal-body"></div>
                            </div>
                        </div>
                    </div>`;

        if ($('#responseModal').length == 0) {
            $('body').append($d);
        }

        var _container = container || '';
        if (type == 'success') {
            var icon = `<i class="fa fa-check-circle" aria-hidden="true"></i>`;
        } else {
            var icon = `<i class="fa fa-exclamation-circle" aria-hidden="true"></i>`;
        }
        var content = '<div class="alert alert-' + type + '"><h3>' + msg + '</h3></div>'
        $('#responseModal .modal-body').html(content);
        $('body').addClass('dark-backdrop');
        $('#responseModal').modal('show').on('hidden.bs.modal', function () {

            $('#responseModal .modal-body').empty();
            $('body').removeClass('dark-backdrop');
            if (with_ajax_modal) {
                $('#ajaxModal').animate({
                    opacity: 1
                }, 300);
            }
        }).on('shown.bs.modal', function () {

            setTimeout(function () {
                if ($('#responseModal').hasClass('show')) {
                    $('#responseModal').modal('hide');
                }
            }, 5000)
        });;
    }, $timeout);


}

function loadScript(url, callback) {
    jQuery.ajax({
        url: url,
        dataType: 'script',
        success: callback,
        async: true
    });
}

function load_css(t) {
    $('head').append('<link rel="stylesheet" href="' + t + '" type="text/css" />');
}

function increaseValue(id) {
    let e = new Event("change");


    var value = parseInt(document.getElementById(id).value, 10),
        max_value = document.getElementById(id).getAttribute("max");
    value = isNaN(value) ? 1 : value;
    value++;
    if (max_value !== null) {
        if (value <= max_value) {
            document.getElementById(id).value = value;
        }
    } else {
        document.getElementById(id).value = value;
    }

    document.getElementById(id).dispatchEvent(e);


}

function decreaseValue(id) {
    let e = new Event("change");
    var value = parseInt(document.getElementById(id).value, 10);
    value = isNaN(value) ? 1 : value;
    value--;
    if (value >= 1) {
        document.getElementById(id).value = value;
    }
    document.getElementById(id).dispatchEvent(e);

}



function createCookie(name, value, hours) {
    var expires;
    if (hours) {
        var date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function load_partial_instantly(container, cb) {

    if (container.length == 0) {
        return false;
    }

    var d = container.data(),
        data = {};

    for (var i in d) {
        if (i != 'partial') {
            data[i] = d[i];
        }
    }


    $('body').addClass('loading');

    container.css('pointer-events', 'none');
    container.css('min-height', container.height());
    container.addClass('loading');
    //container.append('<div class="parial-loader">Loading...</div>');

    if (container.attr('id') == "products-list-filter") {
        container.append(products_loading_template);
    }
    if (container.attr('data-partial') == "products.aggregates") {
        container.append(products_filter_loading_template);
    }



    if (data) {
        data.current_url = window.location.pathname;
    }
    if (container.data('xhr')) {
        container.data('xhr').abort();
    }

    var ext_domain = '';
    if (container.data('ext_domain')) {
        ext_domain = container.data('ext_domain');
    }

    $.ajax({
        url: ext_domain + '/partial/' + d.partial,
        data: data,
        method: 'GET',

        success: function (data) {
            var newContent = $(data.markup);
            var t = container.replaceWith(newContent);

            newContent.css('pointer-events', 'auto');

            if (newContent.text().length == 0) {
                newContent.hide();
            } else {
                newContent.show();
            }

            newContent.removeClass('loading');
            $('body').removeClass('loading');
            // container.find('.parial-loader').remove();
            $('#' + newContent.attr('id')).removeClass('loading');

            newContent.css('min-height', '0');


            if (typeof (cb) !== 'undefined') {
                cb(newContent);
            }

            loading_partial();
            initForm(newContent);
        },
        error: function (response) {
            loading_partial();
        }
    });

    loading_partial();

}
$(document).ready(function() {

    $('#subscribeBtn').click(function() {

        $('#subscribeCol').css({
            'transform': 'translateY(-10px)',
            'opacity': '0'
        });

        $('#subscribeCol').css('display', 'none');
        $('#formCol').css('display', 'block');
        setTimeout(function() {
            $('#formCol').css({
                'transform': 'translateY(0)',
                'opacity': '1'
            });
        });

    });

    $('.back-btn').click(function(e) {
        e.preventDefault();
        $('#subscribeCol').css({
            'transform': 'translateY(10px)',
            'opacity': '0'
        });

        $('#formCol').css('display', 'none');
        $('#subscribeCol').css('display', 'block');
        setTimeout(function() {
            $('#subscribeCol').css({
                'transform': 'translateY(0)',
                'opacity': '1'
            });

            $('#formCol').css({
                'transform': 'translateY(-10px)',
                'opacity': '1'
            });
        });
    })

    $(function() {
        var theForm = document.querySelector('.newsletter_form');

        theForm.addEventListener('submit', function() {
            if ($('input[name="privacy_policy"]').prop('checked')) {
                $('.loading-svg').addClass('showing');
            }
            console.log(theForm);
        });

        $.extend({}, AjaxForms, {
            options: {
                recaptcha: true,
                recaptcha_key: "6LexCyUoAAAAAATPliuD4HHYq6Qw2JoVjFAGcB9Q",

                stop_response: true,
                successFn: function(data) {
                    actionResponseModal(
                        'You have succesfully been subscribed to our e-Newsletter',
                        'success');
                    $('.loading-svg').removeClass('showing');
                    $('#newsletter_form')[0].reset();
                    $('#newsletter_form').find('input[type="checkbox"]').prop('checked',
                        false);
                    $('#newsletter_form').find('button').attr('disabled', 'disabled');


                },
                errorFn: function(data) {
                    $('.loading-svg').removeClass('showing');
                    actionResponseModal(data.error, 'error');
                }
            }
        }).init('#newsletter_form');

        var $input_email = $('#newsletter_form input[name="email"]').parsley();
        var SearchTimer;
        $('input[name="email"]').keyup(function() {
            var $this = $(this);
            clearTimeout(SearchTimer);
            SearchTimer = setTimeout(function() {
                $value = $this.val();
                if ($input_email.isValid()) {
                    $('#newsletter_form').find('button').removeAttr('disabled');
                } else {

                    $('#newsletter_form').find('button').attr('disabled',
                        'disabled');
                }
            }, 300);
        });

    });
});