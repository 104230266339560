var heartbeat = {
	config:{
		delay : 20,
		autoLogOut : false
	},

	init:function(){
		this.poll();
		this.long_poll();
	},
	long_poll : function(){
		var $this 		= this;

		try{
			ga('send', 'event', 'Heartbeat', 'Heartbeat', '', {
			  nonInteraction: true
			});
		}	catch(Error){}

	
		setTimeout(function(){
			
			$this.long_poll();
		
		} , 5 * 60 * 1000);
	},
	poll : function(){
		var $this 		= this;
		var fetch_url 	= '/api/users/heartbeat?v=' + (new Date().toISOString().slice(0,18)); 
		
		$.ajax({
		  url: fetch_url
		}).done(function(response) {
			if($this.config.autoLogOut){
				if(navigator.userAgent != response.useragent){
					document.getElementById('logout-form').submit();
				}
			}

		}).always(function(response) {
			
			setTimeout(function(){
			
				$this.poll();
			
			} , $this.config.delay * 1000);

		});

	}

	
};
