var toggleSliderBtn = document.querySelector('.burger-menu');
var sliderContainer = document.querySelector('.slider-homepage');
var overlay = document.querySelector('.overlay');
var navbar = document.querySelector('header');
var closeBtn = document.querySelector('.close-slider-btn');

$(document).ready(function () {

    var swiper = new Swiper(".slider-homepage", {
        speed: 1500,
        slidesPerView: 1,
        centeredSlides: true,
        keyboard: {
            enabled: true,
        },
        initialSlide: $('.slider-homepage .swiper-slide.initialSlide').index(),
        mousewheel: {
            invert: false,
        },
        breakpoints: {
            991: {
                spaceBetween: 100,
                slidesPerView: 1,
                pagination: {
                    el: ".slider-homepage .swiper-pagination",
                    renderBullet: function (index, className) {
                        var slideName = document.querySelectorAll('.swiper-slide h4')[index]
                            .innerText;
                        return '<span class="' + className +
                            ' custom-bullet"><div class="bullet-text">' + slideName +
                            '</div></span>';
                    },
                    clickable: true,
                },
            },
        },
        on: {
            slideChangeTransitionEnd: function () {
                var activeVideo = document.querySelector('.swiper-slide-active video');
                var videos = document.querySelectorAll('.slider-homepage video');
                videos.forEach(function (video) {
                    video.pause();
                });
                if (activeVideo) {
                    activeVideo.play();
                }
            }
        }

    });

    if (toggleSliderBtn) {
        toggleSliderBtn.addEventListener('click', function () {
            var activeSlideIndex = $('.slider-homepage .swiper-slide.active').index();

            if (activeSlideIndex !== -1) {
                swiper.slideTo(activeSlideIndex);
            }

            openSlider();
        });
    }


    $('.slider-homepage .trigger-pager-link').click(function () {
        if ($(this).closest('.swiper-slide').hasClass('swiper-slide-active')) {
            $(this).closest('.slider-homepage').addClass('p-0');
            var href = $(this).data('href');
            swiper.keyboard.disable();
            swiper.mousewheel.disable();
            setTimeout(function () {
                window.location.href = href;
            }, 800);

        } else {
            swiper.slideTo($(this).closest('.swiper-slide').index());
        }

    });

    function openSlider() {
        navbar.classList.toggle('active-nav');
        $('body').addClass('navigation-open');
        sliderContainer.classList.toggle('show');
        overlay.classList.toggle('show');
        $('.page-body').css('overflow-y', 'hidden');
        $('.hero-wrapper video')[0].pause();
    }

    function closeSlider() {
        navbar.classList.remove('active-nav')
        $('body').removeClass('navigation-open');
        sliderContainer.classList.remove('show');
        overlay.classList.remove('show');
        $('.page-body').css('overflow-y', 'auto');
        $('.hero-wrapper video')[0].play();
    }

    if (closeBtn) {
        closeBtn.addEventListener('click', function () {
            var activeSlideIndex = $('.slider-homepage .swiper-slide.active').index();

            if (activeSlideIndex !== -1) {
                if (swiper.activeIndex === activeSlideIndex) {
                    closeSlider();
                } else {
                    swiper.slideTo(activeSlideIndex);
                    swiper.on('transitionEnd', function () {
                        closeSlider();
                        swiper.off('transitionEnd');
                    });
                }
            } else {
                closeSlider();
            }
        });
    }


});