var checkout = {
  
    init : function(){

        $('.select-address').on('click',function(){
                 
            $('.select-address').removeClass('selected');
            $(this).closest('.address-item').addClass('selected');
            $(this).closest('form').find('#submit').show();
            return false;
        
        });
          /*   
        $('#submit').on('click' , function(){

            checkout.register_step(function(d){
                if(typeof(d.redirect)!=='undefined'){
                    window.location = d.redirect;
                }
            });

            return false;
        });*/
     },

     register_step : function(cb){

        $.ajax({
            type: "POST", 
            url: '/api/orders',
            data: {
                 step : 'place-order',
                  billing_address_id: $('.address-item.selected').data('id'),
                 order_id : $('input[name="order_id"]').val()
            },
            success:function(data){ 
                cb(data);
            }
        });
     }
  
}

async function stripeInitialize(stripe , id) {
    $.ajax({
        type: "GET", 
        url: '/api/payments/' + id,
        success:function(data){ 
           elements = stripe.elements({ clientSecret : data.client_secret });
           const paymentElement = elements.create("payment");
           paymentElement.mount("#payment-element");
        }
    });
}

// Fetches the payment intent status after payment submission
async function checkStatus() {
  
  const clientSecret = new URLSearchParams(window.location.search).get(
     "payment_intent_client_secret"
  );
  
  if (!clientSecret) {
     return;
  }
  
  const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
  
  switch (paymentIntent.status) {
     case "succeeded":
        showMessage("Payment succeeded!");
        break;
     case "processing":
        showMessage("Your payment is processing.");
        break;
     case "requires_payment_method":
        showMessage("Your payment was not successful, please try again.");
        break;
     default:
        showMessage("Something went wrong.");
        break;
  }
}

// ------- UI helpers -------

function showMessage(messageText) {

  const messageContainer = document.querySelector("#payment-message");

  messageContainer.classList.remove("hidden");
  messageContainer.textContent = messageText;

    setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
    }, 4000);
}

// Show a spinner on payment submission
function setLoading(isLoading) {
    if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
    } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
    }
}