var cart = {
    addressesInit: function (target) {
        $slider = new Swiper(target, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            navigation: false,
            allowTouchMove: false,
            initialSlide: typeof ($(target + ' .active').closest('.col-md-6').index()) !== 'undefined' ? $(target + ' .active').closest('.col-md-6').index() : 0,
            breakpoints: {
                320: {
                    allowTouchMove: true,

                },

                768: {
                    allowTouchMove: false,
                },

            }

        });

    },

    init: function () {

    },

    configuration: {},

    add: function ($form) {

        var $this = this;
        
        if ($('.add_to_cart_form .required_variation[value=""]').length > 0) {

            actionResponseModal($('.add_to_cart_form .required_variation[value=""]').data('label'), 'error');

        } else {
            $.ajax({
                type: "post",
                url: "/api/orders",
                data: $($form).serialize(),
                success: function (data) {

                     var $total_quantity = data.totals.products || 0;

                      $this.refresh_totals($total_quantity);

                    //if ($($form).closest('.modal').length > 0) {
                      //  $($form).closest('.modal').modal('hide');
                   // }

                    if (typeof ($($form).find('input[name="product_id"]').val()) !== undefined) {

                       // $('#cart-modal .partial').attr('data-product_cart', $($form).find('input[name="product_id"]').val());
                       // $this.show(true);

                    }


                  //  window.location= '/en/check-out';



                },
                error: function (data) {
                    actionResponseModal(data.responseJSON.error, 'error', '.actions-block .save');
                }

            });
        }

    },

    update: function ($form) {
            var loading_initial_text='';
         if ($('.loading-w').length) {
             loading_initial_text= $('.loading-w .text-center').text();
             $('.loading-w .text-center').text('');
             $('.loading-w').css('pointer-events', 'all').fadeIn();
          }

        var $this = this;
       
        $.ajax({
            type: "PUT",
            url: "/api/orders",
            data: $($form).serialize(),
            success: function (data) {
                var $total_quantity = data.totals.products || 0;

                $this.refresh_totals($total_quantity);

                if (typeof ($($form).find('input[name="product_id"]').val()) !== undefined) {
                    load_partial($($form).closest('.partial'));
                }

                if ($('.loading-w').length) {
                     $('.loading-w').css('pointer-events', 'none').fadeOut( 0, function() {
                               $('.loading-w .text-center').text(loading_initial_text);
                      });
                     
                  }
            },
            error: function (data) {
                actionResponseModal(data.responseJSON.error, 'error', '.actions-block .save');
            }
        });

    },

    remove: function (product_id) {
        var $this = this;

         openPartialModal( $(this), {
            partial : 'ui.confirm'  ,
            title:    'Remove Product?',      
            content:  'Do you really want to remove the selected product from your order?',
            type:     'danger',   
        },function(){
            $("#confirm-yes").on("click", function(){
               
                $.ajax({
                    type: "DELETE",
                    url: "/api/orders",
                    data: {
                        product_id: product_id
                    },
                    success: function (data) {

                        var $total_quantity = data.totals.products || 0;
                        $this.refresh_totals($total_quantity);
                         if (typeof ($('.checkout-section')) !== undefined) {
                            window.location.reload();
                        }

                    },
                    error: function (data) {
                        actionResponseModal(data.responseJSON.error, 'error', '.actions-block .save');
                    }
                });

                $("#confirm-modal").modal('hide');
            });              
            $("#confirm-no").on("click", function(){
                $("#confirm-modal").modal('hide');
            });
        }); 

       
    },

    show: function ($refresh) {
        var $this = this;

        load_partial($('#side-cart.partial'), function () {
            document.getElementById("mySidenav").style.right = "0";
            document.getElementById("ajax-content").style.opacity = "0.5";

        });

        return false;


    },

    add_to_cart_show: function ($id, $refresh) {
        var $this = this;
        $('#add-to-cart-modal .partial').attr('data-product_id', $id);
        load_partial($('#add-to-cart-modal .partial'), function () {
            $('#add-to-cart-modal').modal('show');
        });


    },

    hide: function () {
        document.getElementById("mySidenav").style.right = '-520px';
        document.getElementById("ajax-content").style.opacity = "1";
    },
    refresh_totals: function ($total) {

        $('[data-refresh-after-cart-change]').each(function () {
            load_partial($(this));
        });

        if ($total > 0) {
            $('#cart_counter').text($total);
            $('#cart_counter').closest('a').addClass('filled');
        } else {
            $('#cart_counter').text(0);
            $('#cart_counter').closest('a').removeClass('filled');
        }

    },
    refresh: function () {
        var $this = this;

        load_partial($('[data-partial="orders.cart"]'), function () {

        });

    },


    refresh_minimum_quantity: function ($this, $target) {
        $('#' + $target).attr('min', $($this).closest('li').data('min'))
            .attr('value', $($this).closest('li').data('min'));
        // .attr('max', $($this).closest('li').data('max') ); 


        $('.product_displayed_price').text($($this).closest('li').data('price'));
        $('.product_displayed_discount').text('-' + $($this).closest('li').data('discount') + '%');
        $('.product_displayed__sale_price').text($($this).closest('li').data('sale_price'));


        if ($($this).closest('li').data('discount') > 0) {
            $('.actual-price,.product_displayed_discount,.product_displayed__sale_price').addClass('d-block').removeClass('d-none');
            $('.no-discount-price').addClass('d-none').removeClass('d-block');
        } else {
            $('.actual-price,.product_displayed_discount,.product_displayed__sale_price').addClass('d-none').removeClass('d-block');
            $('.no-discount-price').addClass('d-block').removeClass('d-none');
        }


        if (('#mobile_' + $target).length) {

            $('#mobile_' + $target).attr('min', $($this).closest('li').data('min'))
                .attr('value', $($this).closest('li').data('min'));
            //.attr('max', $($this).closest('li').data('max') ); 



        }





    },

     questionnaireInit: function( ) {
         $('.questionanaire').find( '.select2' ).each( function( ) {
            $(this).select2();
        });

       var $questionnaireSliders = new Swiper( '#swiper-questionanaire', {
                direction: 'horizontal',
                effect: 'fade',
                navigation: false,
                spaceBetween: 10,     
                allowTouchMove:false,        
                watchSlidesProgress: true,
                loop:false,
                fadeEffect: { crossFade: true },
                slidesPerView: 1,
                 pagination: {
                    el: "#swiper-questionanaire .swiper-pagination",
                    type: "fraction",
                },
            } );
            
            $('#questionnaire_country').on('select2:selecting', function (e) {
                
                var $country = e.params.args.data.id;

                if($country.length){

                    $('#questionnaire_city').html('<option value="">Please Select</option>');

                    $(this).closest('.question-item').addClass('loading-item').append('<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>');

                      $.ajax({
                        url: '/cities',
                        data: {
                            country:$country
                        },
                        method: 'GET',

                        success: function (data) {
                           if( data.length >0   ){
                               for (var i = 0; i < data.length; i++) {
                                  $('#questionnaire_city').append($('<option>', {value:data[i].id, text:data[i].name})).trigger('change');
                              }
                            $('.loading-item .lds-roller').remove();
                             $('.loading-item').removeClass('loading-item');
                              $('#questionnaire_city').select2('open');


                           }
                        },
                        error: function (response) {
                           $('.loading-item .lds-roller').remove();
                             $('.loading-item').removeClass('loading-item');
                        }
                    });

                  
                } else{
                     $('#questionnaire_city').html('<option value="">Please Select</option>').trigger('change');

                }
            });


             $('#questionnaire_city').on('select2:selecting', function (e) {
                
                var $city = e.params.args.data.id;

                if($city.length){

                      $.ajax({
                        url: '/cities',
                        data: {
                            id:$city
                        },
                        method: 'GET',

                        success: function (data) {
                          if( data  ){

                              $('#city-data-inputs').append('<input name="data[city]" type="hidden" value="'+data.name+'">');

                              $.ajax({
                                url: 'https://api.openweathermap.org/data/2.5/weather?lat='+data.lat+'&lon='+data.lng+'&exclude=hourly,daily&appid=f275d03f52bb303ac551c5d4938f1b84',
                                 dataType: "jsonp",
                                success: function (data) {
                                    if(data){
                                        var weather= JSON.stringify(data).replace(/"/g, "'");
                                         $('#city-data-inputs').append('<input name="data[weather]" type="hidden" value="'+weather+'">');
                                    }
                                  

                                },
                                error: function (response) {
                                }
                            });



                               $.ajax({
                                  url: '/get_uvi',
                                    data: {
                                        lat:data.lat ? data.lat :'',
                                        lng:data.lng? data.lng :''
                                    },
                                    method: 'GET',

                                success: function (data) {
                                    
                                    if(data){
                                        var all_data = JSON.parse(data);
                                        if(typeof(all_data.uv_max)!=='undefined'){
                                          $('#city-data-inputs').append('<input name="data[uvi]" type="hidden" value="'+all_data.uv_max+'">');
                                        }
                                    }
                                  
                                },
                               error: function(response) {
                                }
                            });
                              


                              $.ajax({
                                url: 'https://api.openweathermap.org/data/2.5/air_pollution?lat='+data.lat+'&lon='+data.lng+'&appid=f275d03f52bb303ac551c5d4938f1b84',
                                 dataType: "jsonp",
                                success: function (data) {
                                    if(data){
                                        var pollution= JSON.stringify(data).replace(/"/g, "'");
                                         $('#city-data-inputs').append('<input name="data[pollution]" type="hidden" value="'+pollution+'">');
                                    }
                                  
                                },
                                error: function (response) {
                                }
                            });

                          }
                            
                        },
                        error: function (response) {
                        }
                    });

                  
                } else{
                    $('#city-data-inputs').html('');
                }
            });




           $(document).on("select2:open", () => {
                   setTimeout( function( ) { 
                        document.querySelector(".select2-container--open .select2-search__field").focus();
                 },100);
            });

            $('.question-item  input,.question-item   select').change(function(){

                if ( $(this).val()!='' || $(this).is(':checked') ) {
                    if($(this).attr('name')!='data[country]'){
                        $(this).closest('.question-item').find('.btn-submit-answer').removeAttr('disabled');
                    }
                    
               }else{
                      $(this).closest('.question-item').find('.btn-submit-answer').attr('disabled','disabled');
                }

                if($(this).is(':checkbox') && typeof($(this).data('limit'))!==undefined ){
                    if ($('input[name="'+$(this).attr('name')+'"]:checked').length > $(this).data('limit') ) {
                        $(this).prop('checked', false);
                    }
                }
               
            });

            $('.question-item  .btn-submit-answer').click(function(){

                if($(this).closest('.question-item').find('input[type="text"],select').length>0 && $(this).attr('name')!='data[country]' &&  $(this).closest('.question-item').find('input[type="text"],select').val()=='' ){
                    actionResponseModal($(this).data('error'),'error');
                }else if($(this).closest('.question-item').find('input[type="checkbox"],input[type="radio"]').length>0 && $(this).closest('.question-item').find('input[type="checkbox"],input[type="radio"]').is(':checked')==false){
                    actionResponseModal($(this).data('error'),'error');
                }else{
                    $questionnaireSliders.slideNext();
                }
                return false;
            });

             $('.question-item  .btn-submit-all-answer').click(function(){

                var questionnaire_check=true;

                $('#swiper-questionanaire input[type="text"],#swiper-questionanaire input[type="email"],#swiper-questionanaire select').each(function() {
                    if($(this).attr('type') =='email'){
                        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        if(!regex.test($(this).val())){
                            questionnaire_check =false;
                            $(this).addClass('with-error');
                            actionResponseModal($(this).data('error'),'error');
                            return false;
                        }
                        
                    }
                    
                     if($(this).val()==''){
                         questionnaire_check =false;
                        actionResponseModal($(this).data('error'),'error');
                        return false;
                     }

                     $(this).removeClass('with-error');
                });
                if(!questionnaire_check) return false;
                 $('#swiper-questionanaire input[type="checkbox"],#swiper-questionanaire input[type="radio"]').each(function() {

                     if(!$(this).is(':checked') && $(this).attr('required')){
                         questionnaire_check =false;
                          $(this).addClass('with-error');
                        actionResponseModal($(this).data('error'),'error');
                        return false;
                     }
                     $(this).removeClass('with-error');
                });

                if(questionnaire_check){
                   $('#swiper-questionanaire').hide();
                   load_partial($('#skin-analysis'));
                }
                    
                
               
                  return false;
            });
           
            $('.btn-previous-answer').click(function(){
                $questionnaireSliders.slidePrev();
                return false;
            });
           

           $('#analysisModal').on('hidden.bs.modal', function () {
               $('#skin-analysis').removeAttr('style').html('');
               $('#product-selection').removeAttr('style').html('');
               $questionnaireSliders.slideTo(0);
               $('#initial-questionanaire-screen').show();
               $('#swiper-questionanaire').hide();
               $('#questionnaire_form')[0].reset();
               $('#city-data-inputs').html('');
                $('#analysisModal').find('.btn-submit-answer').attr('disabled','disabled');
                $('.questionanaire').find( '.select2' ).each( function( ) {
                    $(this).val(null).trigger('change');
               });
              
            });

            $(document).on("click","#order-items-step",function() {
                $('#skin-analysis').hide();
                load_partial($('#product-selection'), function () {
                    var initial_product_selected = $('#analysisModal input[name="product_id"]').val();
                    $('#product-selection .select-product[data-id="'+initial_product_selected+'"]').trigger('click');
                });
            });

           $(document).on("click","#product-selection .select-product",function() {
                $('#analysisModal input[name="product_id"]').val( $(this).data('id'));
                $('#product-selection .select-product').removeClass('selected');
                $(this).addClass('selected');
                return false;
            });
        
           

    },

    empty: function () { }
}
