
var checkout = {

  current_step: 1,

  api_route: '/api/orders',

  order: null,

  init_step: function (step) {

    if (step == 'billing') {

      $('[data-step="' + step + '"] .select-address').on('click', function () {
        $('[data-step="' + step + '"] .select-address').removeClass('selected');
        $('[data-step="' + step + '"] .select-address').parent().addClass('d-none');
        $(this).closest('.address-item').addClass('selected');
        $('[data-step="' + step + '"] .select-address.selected').parent().removeClass('d-none');
        $(this).closest('form').find('.actions').show();
        return false;
      });
      $('[data-step="' + step + '"] .select-address:first').trigger('click');
    }

    if (step == 'shipping') {

      $('[data-step="' + step + '"] .select-address').on('click', function () {
        $('[data-step="' + step + '"] .select-address').removeClass('selected');
        $('[data-step="' + step + '"] .select-address').parent().addClass('d-none');
        $(this).closest('.address-item').addClass('selected');
        $('[data-step="' + step + '"] .select-address.selected').parent().removeClass('d-none');
        $(this).closest('form').find('.actions').show();
        return false;
      });
      $('[data-step="' + step + '"] .select-address:first').trigger('click');
    }

    if (step == 'payment') {

      initPayPal();
    }

  },
  /*
  bypass_step:function(){
   
      $.ajax({
            type: "POST",
            url: checkout.api_route,
            data: {

              step: 'place-order',
              metadata: {},
              order_notes: $('textarea[name="order_notes"]').val(),
              billing_address_id: $('[data-step="billing"] .address-item.selected').data('id'),
              shipping_address_id: $('[data-step="shipping"] .address-item.selected').data('id')

            },
            success: function (data) {
              redirectUrl = BASE_URL + '/' + CURRENT_LANGUAGE + '/thank-you?order_id=' + data.order.id;
              window.location = redirectUrl;
            }
          }); 
  },*/
  load_step: function () {

    load_partial($('.check-out-container .step[data-step="' + checkout.current_step + '"]'), function () {

      $('.check-out-container .step[data-step!="' + checkout.current_step + '"]').hide();
      $('.check-out-container .step[data-step="' + checkout.current_step + '"]').show();

      $('.checkout-sidebar-review  .steps-list .steps-list-item').removeClass('active');
      $('.checkout-sidebar-review  .steps-list .steps-list-item[data-checkout-step="' + checkout.current_step + '"]').addClass('active');
      $('.checkout-sidebar-review  .steps-list .steps-list-item[data-checkout-step="' + checkout.current_step + '"]').parent().prevAll().find('.steps-list-item').addClass('past-step');


      $('.select-address').on('click', function () {

        $('.select-address').removeClass('selected');
        $(this).closest('.address-item').addClass('selected');
        $(this).closest('form').find('.actions').show();
        return false;
      });

      if (checkout.current_step === 2 && $('.cart.checkout-sidebar-review').length > 0) {
        $('.check-out-container .step').parent().removeClass('col-lg-8');
        $('.cart.checkout-sidebar-review').parent().hide();
      } else {
        $('.check-out-container .step').parent().addClass('col-lg-8');
        $('.cart.checkout-sidebar-review').parent().show();
      }

      $('.checkout-register-step').on('click', function () {

        checkout.register_step(function (d) {

          if (checkout.current_step == 1) {
            if (typeof (d.redirect) !== 'undefined') {
              window.location = d.redirect;
            }
          } else {
            checkout.current_step++;
            checkout.load_step();
          }
        });


        return false;
      });




      $('.previous-step').on('click', function () {
        checkout.current_step--;
        checkout.load_step();
        return false;
      });



      $('.steps-nav-item').on('click', function () {
        var step = $(this).data('checkout-step');
        checkout.current_step = step;
        checkout.load_step();
        $(this).parent().prevAll().find('.steps-list-item').removeClass('disabled-step').addClass('past-step');
        $(this).parent().nextAll().find('.steps-list-item').addClass('disabled-step').removeClass('past-step');

        return false;
      });



    });
  },
  get_step_data: function () {
    var last_step = $('.step:last').data('step');

    if (checkout.current_step == 1) {

      return {

        step: 'place-order',
        metadata: {
        },
        billing_address_id: $('[data-step="billing"] .address-item.selected').data('id'),
        shipping_address_id: $('[data-step="shipping"] .address-item.selected').data('id')

      };
    }/*else if(checkout.current_step == 2){
           return {
               step : 'payment-details',
                
            };
        }*/else if (checkout.current_step == 2) {
      return {
        step: 'place-order'
      };
    }
  },
  register_step: function (cb) {

    $.ajax({
      type: "POST",
      url: checkout.api_route,
      data: checkout.get_step_data(),
      success: function (data) {
        cb(data);
      },
      error: function (data) {
        actionResponseModal(data.responseJSON.error || 'An unknown error occurred. Please try again', 'error')
      }
    });
  }
}
function initPayPal() {

  $.ajax({
    type: "POST",
    url: '/api/paypal/orders',
    success: function (data) {

      var script = document.createElement('script');
      script.setAttribute('data-client-token', data.client_token);

      script.onload = function () {
        paypal
          .Buttons({
            createOrder: function (data, actions) {
              return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [{
                  amount: {
                    value: checkout.order.grand_total_amount
                  }
                }]
              });
            },
            onApprove: function (data) {

              return $.ajax({
                type: "POST",
                data: {
                  transaction_id : data.orderID
                },
                url: `/api/paypal/capture`,
                dataType:'json',
                success: function (orderData) {
                  if (orderData.id) {
                    $.ajax({
                      type: "POST",
                      url: checkout.api_route,
                      data: {
  
                        step: 'place-order',
                        metadata: {},
                        order_notes: $('textarea[name="order_notes"]').val(),
                        billing_address_id: $('[data-step="billing"] .address-item.selected').data('id'),
                        shipping_address_id: $('[data-step="shipping"] .address-item.selected').data('id')
  
                      },
                      success: function (data) {
                        redirectUrl = BASE_URL + '/' + CURRENT_LANGUAGE + '/thank-you?order_id=' + data.order.id;
                        window.location = redirectUrl;
                      }
                    }); 
                  } else {
                    alert('an error occured. please try again later');
                  }
                  
 
                }

              });

            },
          })
          .render("#paypal-button-container");


      };
      script.src = 'https://www.paypal.com/sdk/js?components=buttons&client-id=AWVs1nl49tgz4zXvFDmB4Wka_wyQfCy4YXU8vTdv-y9wsZLXCI8gn9XKSwDkkl55M_qJqz6UMbxZamj8&currency=GBP&intent=capture';

      document.head.appendChild(script); //or something of the likes


      return false;


    }
  });





}