var notifications = {
	
	config:{
		delay : 10,
		client_id : 0,
		user_groups : [],
		user_id : 0,
		env : '',
		base_url : '/api/notifications/poll' 
	},
 
	notifications_list 		: [],
	notifications_list_raw 	: [],

	init:function(){
		this.poll();
	},

	discard_notification:function(notification_item){
		localStorage.setItem('notification_item_' + notification_item , 'discard');
	},
	callback : function(notification_item){
		var $this = this;
		var notification = $this.notifications_list_raw.filter(function(i){ return i.id == notification_item})[0];
		switch(true){
			case 'navigation' == notification.payload.action || 'auto-redirect' == notification.payload.action:
				localStorage.setItem('notification_item_' + notification.id , 'interaction');
				if(notification.payload.nodeid && notification.payload.nodeid.length > 0){
					window.location = notification.payload.url + '?nodeid=' + notification.payload.nodeid;
				}else{
					window.location = notification.payload.url;
				}
				break;
			
			case 'function' == notification.payload.action:
				localStorage.setItem('notification_item_' + notification.id , 'function');
				var fn = notification.payload.fn;
				window[fn](notification.payload.params)
				break;

		}
	},

	valid_notifications : function(notifications){
		
		return notifications.filter(function(item){
		
			if( localStorage.getItem('notification_item_' + item.id ) !== null ){
				return false;
			}
			var now 	= moment(moment().tz( 'America/Los_Angeles' ).format("MM/DD/YYYY HH:mm"), "MM/DD/YYYY HH:mm");
			var start 	= moment(item.starts_at, "MM/DD/YYYY HH:mm");
			var end 	= moment(item.ends_at, "MM/DD/YYYY HH:mm");
			
			return (now.isBetween(start, end , undefined , '[]'));
		});
	},

	add_modal_notification:function(notification){
	
		var markup = '<div class="modal fade notification-modal" id="notification-popover-'+notification.id+'" role="dialog">'
						  +'<div class="modal-dialog" role="document">'
						    +'<div class="modal-content">'
						      +'<div class="modal-body">'
						      		+'<img src="'+notification.image+'" style="width:100%;cursor:pointer"   onclick="notifications.callback(\''+notification.id+'\');" data-id="'+ notification.id +'">  '
						          +'	<button type="button" class="close" data-dismiss="modal" aria-label="Close" onclick="notifications.discard_notification(\''+notification.id+'\');">'
						         +' <svg style="fill: #ffffff" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 13 13" style="enable-background:new 0 0 13 13;" xml:space="preserve">'
						            +'   <path class="st0" d="M12.9,11.7l-1.2,1.2L6.9,8.1l-4.8,4.8l-1.2-1.2l4.8-4.8L0.9,2.1l1.2-1.2l4.8,4.8l4.8-4.8l1.2,1.2L8.1,6.9 L12.9,11.7z"></path>'
						           +'   </svg>'
						          +'</button>'
						  		+'<div class="notification-content">'+notification.content+'</div>'
						     +' </div>'
						   +' </div>'
						  +'</div>'
						+'</div>';

		$('body').append(markup);
		
		$('#notification-popover-' + notification.id).modal('show');

	},

	close_modal_notification:function(){

	},
	
	poll : function(){
		

		var $this = this;

		var promises = [

		];

		//var fetch_url = $this.config.base_url+$this.config.client_id+'/'+$this.config.env+'/user-'+$this.config.user_id+'/notification.json?v=' + (new Date().toISOString().slice(0,18));
		var fetch_url = $this.config.base_url+'?v=' + (new Date().toISOString().slice(0,18));
		/*promises.push(fetch(fetch_url).then(function(resp){
	  		return resp.json();
	  	}));
*/
		for(var i in $this.config.user_groups){

			//var fetch_url = $this.config.base_url+$this.config.client_id+'/'+$this.config.env+'/group-'+$this.config.user_groups[i]+'/notification.json?v=' + (new Date().toISOString().slice(0,18));
		var fetch_url = $this.config.base_url+'?v=' + (new Date().toISOString().slice(0,18));

			promises.push(fetch(fetch_url).then(function(resp){
		  		return resp.json();
		  	}));
		}

		Promise.all(promises).then(function(fetches){
			
			var retrieved_notifications = [];

			for(var ii in fetches){
				
				var response = fetches[ii];
				var init_slider = false;
				var data = $this.valid_notifications(response);

				for(var i in data){
					
					var notification 			= data[i];							
					var notification_id 		= Number(notification.id);
					var notification_content 	= notification.content;
					
					retrieved_notifications.push( notification_id );

					if($this.notifications_list.indexOf( notification_id ) == -1){
						
						$this.notifications_list.push( notification_id );
						$this.notifications_list_raw.push( notification );
						
						if(notification.type == 'modal'){
							
							$this.add_modal_notification(notification);

						}else if(notification.type == 'top-nav'){
							
							$('.notifications-slider').show();
							$('.notifications-slider-content').append('<div class="slick-slide" style="cursor:pointer" onclick="notifications.callback('+notification_id+');" data-id="'+ notification_id +'" >'+notification_content+'</div>' );

							if(notification.payload.action == 'auto-redirect'){
								
								setTimeout(function(){
									
									notifications.callback(notification_id);

								},5000);
							}
							
							init_slider = true;
						}
					}
				}
			}

			$('.notifications-slider .notifications-slider-content .slick-slide').each(function(){
				if(retrieved_notifications.indexOf( Number( $(this).data('id'))) == -1){
					$(this).remove();
				}
			});

			if(init_slider && $('.notifications-slider .notifications-slider-content .slick-slide').length > 0 ){
				
				var $slider_content = $('.notifications-slider .notifications-slider-content');

				$('.notifications-slider').show();
				
				if ($slider_content.hasClass('slick-initialized')) {
					$slider_content.slick('destroy');
				}  

				$slider_content.slick({			
					dots: false,
					arrows: false,
					infinite: true,
					speed: 300,
					swipe:false,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
				  	fade: true,
		  			autoplaySpeed: 5000,	

				});		
			}else if( $('.notifications-slider .notifications-slider-content .slick-slide').length == 0 ){
				$('.notifications-slider').hide();
				if ($slider_content.hasClass('slick-initialized')) {
					$slider_content.slick('destroy');
				}  
			}
			
			$this.delayed_poll();

		}).catch(function(error) {
			$this.delayed_poll();
		});
	},

	delayed_poll:function(){

		var $this = this;

		setTimeout(function(){
			
			$this.poll();
		
		} , $this.config.delay * 1000);
	}

};